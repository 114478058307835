<template>

	<div class="orderInfo">
		<div class="title">
			<p>我的订单</p>
			<img src="../../assets/other/right.png" alt="">
			<p>{{views.order_status | stu}}</p>
			<img src="../../assets/other/right.png" alt="">
			<p class="active">订单详情</p>
		</div>
		<!-- 1 -->
		<div class="order1">
			<div class="top flex-between">
				<p class="title">订单信息</p>
				<!-- 待付款 -->
				<div class="buts" v-if="views.order_status==0">
					<!-- <div class="pay" @click="edit(views)">修改订单</div> -->
					<!-- <div class="edit" @click="pay2(views)">支付宝支付</div> -->
					<div class="edit" @click="pay(views)">微信支付</div>
					<div class="cancel"  @click="del(views,1)">取消订单</div>
				</div>
				<!-- 待出行 -->
				<div class="buts"  v-if="views.order_status==1">
					<!-- <div class="pay">下载合同</div> -->
					<!-- <div class="edit" @click="showab">下载合同</div> -->
					<!-- <div class="edit" @click="showab" >索要发票</div> -->
					<div class="cancel" style="position: relative;" @click="del(views,2)" >
						我要退订
						<!-- <div class="td" v-show="visible2">
							<p @click="alltd">整单退订</p>
							<div style="border-bottom: 1px solid #fff;"></div>
							<p @click="bftd">部分退订</p>
						</div> -->
					</div>
				</div>
				<!-- 已出行 -->
				<div class="buts" v-if="views.order_status==2">
					<!-- <div class="pay">下载合同</div> -->
					<!-- <div class="edit" @click="showab" >索要发票</div> -->
					<!-- <div class="edit" @click="showtick">开票信息</div> -->
					<!-- <div class="cancel" @click="pj">立即评价</div> -->
				</div>
				<!--评价 -->
				<div class="buts" v-if="views.order_status==8">
					<!-- <div class="pay">下载合同</div> -->
					<!-- <div class="edit" @click="showab" v-if="views.is_billing==0">索要发票</div> -->
					<div class="edit" @click="showtick"  v-if="views.is_billing==1">开票信息</div>
					<!-- <div class="cancel" @click="pj">立即评价</div> -->
				</div>

			</div>
			<div class="top">
				<p class="title2">{{views.goods_name}}</p>
			</div>
			<div class="r1">
				<div>
					<p>订单号</p>
					<p>{{views.order_sn}}</p>
				</div>
				<div>
					<p>预定日期</p>
					<p>{{views.create_date}}</p>
				</div>
				<div>
					<p>出发城市</p>
					<p>{{views.city}}</p>
				</div>
			</div>
			<div class="r1">
				
				<div>
					<p>出行日期</p>
					<p>{{views.start_time}}~{{views.end_time}}</p>
				</div>
				<div>
					<p>总价 (元)</p>
					<p>
						<span>{{views.order_price}}</span>
						<el-popover placement="bottom" width="190" v-model="visible" trigger="hover">
							<div class="priceinfo">
								<p class="flex-between"><span>产品总价</span><span>¥{{views.goods_price}}</span></p>
								<p class="flex-between"><span>优惠券</span><span>{{views.coupon_price | peice}}</span></p>
								<p class="flex-between"><span>奖励积分</span><span>{{views.discount_integral | peice}}</span></p>
								<p class="flex-between"><span>退款金额</span><span>{{views.refund_price | peice}}</span></p>
								<p class="allmoney">总价<span class="span">¥{{views.order_price}}</span></p>
							</div>
							<span slot="reference" class="mx">费用明细</span>
						</el-popover>
					</p>
				</div>
				<div>
					<!-- <p>集合点</p>
					<p>{{views.place_address}}</p> -->
				</div>
			</div>

		</div>
		<!--  -->

		<!-- 2 预定人信息-->
		<div class="order1">

			<div class="top">
				<p class="title">预定人信息</p>
			</div>
			<div class="r1">
				<div>
					<p>报名人数</p>
					<p>{{views.adult_number}}成人 {{views.children_number}}儿童</p>
				</div>
				
				<div>
					<p>姓名</p>
					<p>{{views.pre_real_name}}</p>
				</div>
				<div></div>
			</div>
			<div class="r1">
				<div  v-if="views.order_status==5">
					<p>退款人数：</p>
					<p>{{views.refund_num || "0"}}成人 {{views.refund_num_2 || "0"}}儿童</p>
				</div>
				
				<div>
					<p>手机</p>
					<p>{{views.pre_phone}}</p>
				</div>
				
				<div>
					<p>备注</p>
					<p>{{views.pre_note}}</p>
				</div>
				<div v-if="views.order_status!=5"></div>
			</div>

		</div>

		<!-- 3 出行人信息-->
		<div class="order3">

			<div class="top">
				<p class="title">出行人信息</p>
			</div>
			<div class="rbox">
				<div class="box" v-for="(item,i) in lists" :key="i">
					<div class="b1 flex-between">
						<p>
							<span>{{item.real_name}}</span>
							<span>{{item.sex | sexshow}}</span>
							<span>{{item.type_id | oldtype}}</span>
						</p>
						<p>{{item.phone}}</p>
					</div>
				 
					<div class="b1 flex-between">
						<p>
							<span>证件类型</span>
							<span>{{item.card_type | cardtype}}</span>
							 
						</p>
						<p>出发站点：{{item.place_address}}</p>
					</div>
					<div class="b1">
						<p>证件号码</p>
						<p>{{item.card_number}}</p>
					</div>
					<div class="b1">
						<p>出生日期</p>
						<p>{{item.birthday}}</p>
					</div>
					<div class="b1" v-if="item.refund_personal_remark">
						<p>退款备注</p>
						<p>{{item.refund_personal_remark}}</p>
					</div>
					<img src="@/assets/user/ytk.png" class="ytk" v-if="item.is_back==1">
				</div>
			</div>

		</div>

		<!-- 4 保险方案-->
		<div class="order4">
			<div class="top">
				<p class="title">保险方案</p>
			</div>
			<div class="r4">
				<p>该旅行产品中已包含基础保险方案，请知悉。</p>
			</div>
		</div>
		<!-- 5扫码进群 -->
		<div class="order4" v-if="views.order_status==1">
			<div class="top">
				<p class="title">扫码进群</p>
			</div>
			<div class="r4">
				<img :src="views.code_img" alt="" width="200px">
				<p style="color: #FF7C00;"> 请扫码加入本次活动微信群</p>
			</div>
		</div>
		<!-- 开发票模态框 -->
		<div id="fps">
			<el-dialog  :visible.sync="dia"  title="索要发票" width="850px">
				<div class="fp">
					<p>说明：发票将实行到付，快递费由您自行承担</p>
					<div class="ftitle">
						<p>选择开票信息</p>
						<p class="but" @click="dia2=true">添加开票信息</p>
					</div>
					<div class="table">
						<table border="1" cellpadding="0" cellspacing="0" >
							<tr>
								<th></th>
								<th>开具类型</th>
								<th>发票类型</th>
								<th>发票抬头</th>
								<th>发票内容</th>
								<th>税务登记证号</th>
								<th>其他信息</th>
							</tr>
							<tr v-for="(item,i) in billinglist" :key="i">
								<td style="width:60px" @click="bilradio=item.id">
									<img src="../../assets/public/check1.png" v-if="item.id==bilradio" />
                        			<img src="../../assets/public/check2.png" v-else  />
								</td>
								<td>{{item.issue_type}}</td>
								<td>{{item.billing_type}}</td>
								<td>{{item.billing_rise}}</td>
								<td>{{item.content}}</td>
								<td>{{item.chind}}</td>
								<td>{{item.billing_type}}</td>
								
							</tr>
							
						</table>
					</div>
					<div class="ftitle">
						<p>选择收件地址</p>
						<p class="but" @click="addAdress">添加常用地址</p>
					</div>
					<div class="table">
						<table border="1" cellpadding="0" cellspacing="0" >
							<tr>
								<th></th>
								<th>联系人</th>
								<th>手机号</th>
								<th>地址</th>
							</tr>
							<tr v-for="(item,i) in addresslist" :key="i">
								<td style="width:60px"  @click="addradio=item.id">
									<img src="../../assets/public/check1.png" v-if="item.id==addradio" />
                        			<img src="../../assets/public/check2.png" v-else  />
								</td>
								<td>{{item.name}}</td>
								<td>{{item.phone}}</td>
								<td>{{item.address}}</td>
							</tr>
							
						</table>
						<div class="butt">
							<p @click="uptick">确定</p>
						</div>
					</div>
					
				</div>
			</el-dialog>
		</div>
		<!-- 添加发票模态框 -->
		<div class="table">
			<el-dialog :visible.sync="dia2"   title="索要发票" width="450px">
				<div class="fp">
					<p>
						<!-- <strong>索要发票</strong> -->
					说明：发票将实行到付，快递费由您自行承担</p>
					<div class="fpform">
						<div>
							<p>开具类型</p>
							<p>
								<el-radio v-model="form1.issue_type" label="个人"  >个人</el-radio>
								<el-radio v-model="form1.issue_type" label="企业" >企业</el-radio>
							</p>
						</div>
						<div>
							<p>发票类型</p>
							<p>
								<el-radio v-model="form1.billing_type" label="增值税普通发票">增值税普通发票</el-radio>
							</p>
						</div>
						<div>
							<p>发票抬头</p>
							<p>
								<input type="text" v-model="form1.billing_rise" placeholder="请输入发票抬头" style="text-indent: 10px;">
							</p>
						</div>
						<div>
							<p>发票内容</p>
							<p>
								<el-select v-model="form1.content" placeholder="请选择">
									<el-option
									v-for="item in options"
									:key="item.value"
									:label="item.label"
									:value="item.value">
									</el-option>
								</el-select>
							</p>
						</div>
						<div v-if="form1.issue_type=='企业'">
							<p>税务登记证号</p>
							<p>
								<input type="text" v-model="form1.chind" placeholder="请填写税务登记证号" style="text-indent: 10px;">
							</p>
						</div>
						<div class="butt">
							<p @click="addBilling">确定</p>
						</div>
					</div>
				</div>
			</el-dialog>
		</div>
		<!-- 立即评价 -->
		<el-dialog :visible.sync="dia3"   title="评价" width="550px">
			<div class="fp">
				<p class="star">
					<span>行程满意度</span>
					<el-rate v-model="form2.travel_score" show-score text-color="#ff9900" >
					</el-rate>
				</p>
				<p class="star">
					<span>领队满意度</span>
					<el-rate v-model="form2.leader_score" show-score text-color="#ff9900" >
					</el-rate>
				</p>
				<p class="star">
					
					<textarea placeholder="快来填写本次旅行的感受吧～" v-model="form2.content"></textarea>
				</p>
				<div>
					<el-upload
					:action='baseURL+"index.php/index/file/upload"'
					list-type="picture-card" 
					name="image"
					:on-success="handleAvatarSuccess"
					:on-preview="handlePictureCardPreview"
					:on-remove="handleRemove">
					<i class="el-icon-plus"></i>
					  <div slot="tip" class="el-upload__tip">可多图上传</div>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
					<img width="100%" :src="dialogImageUrl" alt="">
					
					</el-dialog>
				</div>
				<div class="butt">
					<p @click="upcomment">确定</p>
				</div>
			</div>
		</el-dialog>
		
		<!-- 开票信息 -->
		<el-dialog  :visible.sync="dia4"  title="开票信息" width="650px">
				<div class="table">
					
					<div class="ftitle">
						<p>发票信息</p>						
					</div>
					<div class="table">
						<table border="1" cellpadding="0" cellspacing="0" >
							<tr>
								<th>开具类型</th>
								<th>发票类型</th>
								<th>发票抬头</th>
								<th>发票内容</th>
								<th>税务登记证号</th>
							</tr>
							<tr>
								<td>{{cateView.issue_type}}</td>
								<td>{{cateView.billing_type}}</td>
								<td>{{cateView.billing_rise}}</td>
								<td>{{cateView.content}}</td>
								<td>{{cateView.chind}}</td>
								
							</tr>
							
						</table>
					</div>
					<div class="ftitle">
						<p>物流信息</p>
					</div>
					<div class="table">
						<table border="1" cellpadding="0" cellspacing="0" >
							<tr>
								<th>物流公司</th>
								<th>物流单号</th>
							</tr>
							<tr>
								<td>{{cateView.company | kong}}</td>
								<td>{{cateView.order_on | kong}}</td>
							</tr>
							
						</table>
						
					</div>
					
				</div>
			</el-dialog>
			<!--  请使用微信扫描二维码-->
			<dia-pay ref="payCode" :id="id"></dia-pay>  
			<!-- 地址 -->
			<diaUserAdress ref="adress" @getlist="showab"></diaUserAdress>
	</div>

</template>

<script>
import { orderViewAPI,addressBillinglistAPI,addBillingAPI,upBillingAPI,orderCommentAPI,BillingInfoAPI,orderDel} from "@/api/user";
import {releaseCouponAPI,alipayFormAPI } from "@/api/signup"
import diaPay from '@/components/com/diaPay.vue'
import diaUserAdress from '@/components/com/diaUserAdress.vue'
import {baseURL} from "@/http/base"
export default {
components: {
    diaPay,diaUserAdress
  },
  data () {
    return {
	  baseURL:baseURL,
      sign: 1,
      visible: false,
      visible2: false,
      dia: false,
	  dia2: false,
	  dia3:false,
	  dia4:false,
      radio: '1',
	  views:{
		  is_billing:1
	  },
	  lists:[],
	  addresslist:[],
	  billinglist:[],
	  id:"0",
	  addradio:0,
	  bilradio:0,
	  form1:{
		  id:"0",
		  issue_type:"个人",
		  billing_type:'增值税普通发票',
		  billing_rise:"",
		  content:"",
		  chind:""
	  },
	 options: [{
          value: '旅游费',
          label: '旅游费'
        }, {
          value: '服务费',
          label: '服务费'
        }],
	   form2:{
		  travel_score:5,
		  order_sn:"",
		  content:"",
		  leader_score:5,
		  img:""
	  },
	  cateView:{},
		value:1,
		dialogImageUrl: '',
        dialogVisible: false,
		fileList:[],
    }
  },
  filters:{
	stu(s){
		// 0 待付款 1=未出行；2=已出行；3=已取消； 8 未评价
		switch(s){
			case 0:
				return "待付款"
			break;
			case 1:
				return "未出行"
			break;
			case 2:
				return "已出行"
			break;
			case 3:
				return "已取消"
			break;
			case 4:
				return "退订中"
			break;
			case 5:
				return "已退订"
			break;
			case 8:
				return "未评价"
			break;
		}

	},
	 oldtype(t){
          if (t==1) {
              return "成人"
          }else{
              return "儿童"
          }
      },
      cardtype(t){
       let arr=["未知","身份证","军官证","港澳通行证","护照","回乡证","台胞证"]
          let i=parseInt(t)
          return arr[i]
      },
	kong(k){
		if (k=="") {
			return "-"
		}else{
			return k
		}

	},
	peice(p){
		if (p) {
			return "- "+p
		}else{
			return "¥ 0"
		}
	}
  },
  created () {
	   let id=this.$route.query.id
		this.id=id
		this.form1.id=id
	   this.getview(id)
  },
  methods: {
	  pj(){
		  if(this.views.is_evaluation==0){
			  this.dia3=true
		  }else{
			  this.$message("您已评价过请勿重复评价")
		  }
		  
	  },
	addAdress(){
        this.$refs.adress.dia=true
    },
	pay2(item){
		if(item.is_audit==1){
			var data={
				order_sn:item.order_sn,
				return_url:process.env.VUE_APP_PC_URL+"#/user/orderInfo?id="+this.id
			}
			alipayFormAPI(data).then(res=>{
				console.log(res.pay_form)
				const div = document.createElement('formdiv');
				div.innerHTML = res.pay_form;
				document.body.appendChild(div);
				document.forms['alipaysubmit'].setAttribute('target', '_blank');
				document.forms['alipaysubmit'].submit();
				div.remove();
			})
		}else{
		this.$message.info(item.is_audit==2 ? "审核未通过" : "请等待审核")
		}
		 
	},
	 pay(item){   
		if(item.is_audit==1){
			
			let data={
				order_sn:item.order_sn,
				pay_amount:item.goods_price
			}
			this.$refs.payCode.dialogVisible=true
            this.$nextTick(()=>{               
                this.$refs.payCode.paydata=data
                this.$refs.payCode.pay()
            })
		   }else{
			this.$message.info(item.is_audit==2 ? "审核未通过" : "请等待审核")
		   }
      },
	
	  del(item,n){
		if(n==2){
			this.$message.success('退订请拨打：400-888-9318')
			return
		}
		if(item.order_status==4){
			this.$message.success('正在审核中，请勿重复操作')
			return
		}
		var tip=n==1 ? '取消成功' : '退订成功，请等待审核'
		this.$confirm('此操作将'+n==1 ? '取消' : '退订'+'该订单('+this.views.order_price+'元), 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
			orderDel({id:item.id}).then(res=>{
				this.$message.success(tip)
				this.getview(this.id)
			})         
        }).catch(() => {
        //   this.$message({
        //     type: 'info',
        //     message: '撤销操作'
        //   });          
        });
		
	},
	  handleRemove(file, fileList) {
        console.log(file, fileList);
		var arr=[]
		
		for (let index = 0; index < fileList.length; index++) {
			arr.push(fileList[index].response.data.file_url)
			
		}
		this.fileList=arr
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
	  handleAvatarSuccess(res, file) {
        this.fileList.push(res.data.file_url) 
		console.log(res,file)
      },
      beforeAvatarUpload(file) {
        // const isJPG = file.type === 'image/jpeg/png/jpg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isLt2M;
      },

	edit(item){
		releaseCouponAPI({id:item.id}).then(res=>{
			this.$router.push({ path: '/signup',query:{id:item.price_id,oid:item.id} })
		})
	},
    alltd () {
      this.$confirm('确定整单全退？', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			type: 'warning'
      }).then(() => {
			this.$message({
			type: 'success',
			message: '全退成功!'
			})
      }).catch(() => {
			this.$message({
			type: 'info',
			message: '已取消全退'
			})
      })
    },
    bftd () {
      console.log(22)
    },
	getview(id){
		 orderViewAPI({id}).then(res=>{
			this.views=res.order
			this.lists=res.order_goods	
			this.form2.order_sn=res.order.order_sn		
		  })
	},
	showab(){		
		addressBillinglistAPI({id:this.id}).then(res=>{
			this.addresslist=res.user_address
	   		this.billinglist=res.user_billing
			
			this.dia=true
		})
	},
	cliadd(id){
		this.addradio=id
	},
	addBilling(){
		addBillingAPI(this.form1).then(res=>{
			this.getview(this.id)
			this.dia2=false
			this.dia=false
		})
	},
	uptick(){
		var obj={
			id:this.id,
			aid:this.bilradio,
			bid:this.addradio
		}
		upBillingAPI(obj).then(res=>{
			this.dia=false
			this.getview(this.id)
			this.$message("提交成功！")
		})
	},
	upcomment(){
		this.form2.img=this.fileList.join(",")
		orderCommentAPI(this.form2).then(resa=>{
			this.dia3=false
			this.$message("评论已提交！")
		})
	},

	showtick(){
		
		BillingInfoAPI({id:this.id}).then(res=>{
			this.cateView=res.cateView
			this.dia4=true
		})
	}
  }
}
</script>

<style lang="scss" scope>
.paycode{        
	h2,p{
		text-align: center;
		line-height: 45px;
	}
}
.title{
	display: flex;
	align-items: center;
	margin: 5px 0;

	p{
		font-size: 14px;
		color: #666666;
		line-height: 21px;
	}
	.active{
		color: #FF7C00;
	}

	img{
		width: 10px;
		height: 11px;
		margin: 0 10px;
	}
}
.priceinfo{
	p{
		font-size: 14px;
		line-height: 35px;
		span{
			color: #666;
		}
		span:nth-child(1){
			color: #333333;
		}
	}
	.allmoney{
		text-align: right;

		.span{
			color: #FF7C00;
			margin-left: 10px;
		}
	}

}

.fp .el-dialog__header{
	display: none;
}
.el-dialog__body{
	padding:10px 20px;
}
.fp{
	.el-radio__input.is-checked .el-radio__inner {
		border-color: #ff7c00;
		background: #ff7c00;
	}
	.el-radio__input.is-checked+.el-radio__label {
		color: #ff7c00;
	}
	>p{
		line-height: 40px;
		font-size: 12px;
		color: #999;
		strong{
			font-size: 16px;
			margin-right: 15px;
		}
	}
	.fpform{
		>div{
			display: flex;
			align-items: center;
			line-height: 40px;
			color: #333333;
			p:nth-child(1){
				width: 100px;
			}
			input{
				background: #f6f7f9;
				border-radius: 4px;
				width: 287px;
				height: 30px;
				
			}
		}
	}
	.butt{
		p{
			width: 70px;
			line-height: 35px;
			opacity: 1;
			font-size: 14px;
			background: #ff7c00;
			border-radius: 2px;
			text-align: center;
			color: #ffffff;
			margin:10px auto;
			cursor: pointer;
		}
	}
		.ftitle{
		display: flex;
		align-items: center;
		p{
			color: #333333;
			font-size: 14px;
		}
		.but{
			text-align: center;
			color: #ff7c00;
			border: 1px solid #c2c2c2;
			border-radius: 3px;
			padding:3px 8px;
			margin-left: 20px;
			cursor: pointer;
		}
	}
}
.orderInfo{
	p{
		font-size: 14px;
		color: #666;
	}
	.order1{
		padding: 10px;
		// height: 180px;
		background: #ffffff;
		border-radius: 6px;
		margin: 15px 0;
		.top{

			.title{
				font-size: 18px;
				font-weight: 500;
				color: #333333;
				line-height: 25px;
			}
			.title2{
				font-size: 16px;
				font-weight: 500;
				color: #333333;
				line-height: 25px;
				margin-top: 10px;
			}
			.buts{
				display: flex;
				>div{
					cursor: pointer;
					margin-left:15px;
					width: 90px;
					height: 30px;
					border-radius: 5px;
					font-weight: 500;
					text-align: center;
					line-height: 30px;
				}
				.td{

					position: absolute;
					padding:0 6px;
					top: 33px;
					left: -1px;
					width: 90px;
					height: 80px;
					background: rgba(0,0,0,0.50);
					border-radius: 4px;
					p{
						line-height: 40px;
						color: #fff;
					}
				}
				.pay{
					background-color: #FF7C00;
					color: #fff;
					border: 1px solid #ff7c00;
				}
				.edit{
					border: 1px solid #ff7c00;
					color: #FF7C00;
				}
				.cancel{
					border: 1px solid #999999;
					color: #999;
				}
			}
		}

		.r1{
			display: flex;
			margin-top: 20px;
			>div{
				flex: 1;
				display: flex;
				p:nth-child(1){
					width: 70px;
					color: #333;
				}
				span{
					color: #ff4c12;
					font-size: 18px;
					font-weight: 500;
					margin-right: 10px;
				}
				.mx{
					font-size: 14px;
					color: #666;
				}
			}
		}
	}

	.order3{
		padding: 10px;
		// height: 180px;
		background: #ffffff;
		border-radius: 6px;
		margin: 15px 0;
		.top{
			.title{
				font-size: 18px;
				font-weight: 500;
				color: #333333;
				line-height: 25px;
			}
		}
		.rbox{
			display: flex;
			flex-wrap: wrap;
			.box{
				width: 480px;

				opacity: 1;
				background: #ffffff;
				border: 1px solid #e6e6e6;
				border-radius: 7px;
				margin: 10px;
				padding:0 10px 10px;
				position: relative;
				.ytk{
					position: absolute;
					right: 20px;
					bottom: 20px;
					width: 60px;
				}
				.b1{
					display: flex;
					margin-top: 10px;
					p:nth-child(1){
						color: #333;
						margin-right: 10px;
					}
					p{
						span{
							margin-right: 10px;
						}
					}
				}
			}

		}
	}

	.order4{
		padding: 10px;
		// height: 180px;
		background: #ffffff;
		border-radius: 6px;
		margin: 15px 0;
		.top{
			.title{
				font-size: 18px;
				font-weight: 500;
				color: #333333;
				line-height: 25px;
			}
		}
		.r4{
			p{
				line-height: 50px;
			}
		}

	}
}
.table{

	table{
		width: 100%;
		border-color:#fcfcfc; 
		margin-top: 20px;
		margin-bottom: 30px;
		th{
			background: #eaeaea;
			line-height: 30px;
			// width: 25%;
			font-size: 13px;
			color: #666666;
		}
		td{
			text-align: center;
			line-height: 40px;
			font-size: 13px;
		}
		
	}
	
}
.star{
	margin: 4px 0;
	display: flex;
	align-items: center;

	
	line-height: 50px;
	display: flex;
	align-items: center;
	>span{
		width:100px;
		font-size: 18px;
		line-height: 50px;
	}
	.el-rate__icon{
		font-size: 25px;
		
	}
	textarea{
		width: 457px;
		height: 120px;
		opacity: 1;
		background: #f6f7f9;
		border-radius: 4px;
		opacity: 1;
		font-size: 14px;
		text-align: left;
		color: #999999;
		line-height: 26px;
		padding: 10px;
	}
	
}
</style>
